import { createWebHistory, createRouter } from "vue-router";
import { auth } from '../firebase'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { },
    component: () => import(  '../views/public/page')
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>  import ('../views/login.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () =>  import ('../views/logout.vue')
  },
  {
    path: '/franchise-enquiry-form',
    name: 'Enquiry',
    component: () =>  import ('../views/public/enquiry.vue')
  },
  {
    path: '/confidential-enquiry',
    name: 'Confidential Enquiry',
    meta: { requires_auth: true },
    component: () =>  import ('../views/public/confidential-enquiry.vue')
  },
  {
    path: '/news',
    name: 'All News',
    component: () =>  import ('../views/public/articles/index.vue')
  },
  {
    path: '/news/:id',
    name: 'News Article',
    component: () =>  import ('../views/public/articles/show.vue')
  },
  {
    path: '/testimonials',
    name: 'Testimonials & Reviews',
    component: () =>  import ('../views/public/testimonials/index')
  },
  {
    path: '/testimonials/:id',
    name: 'Testimonial',
    component: () =>  import ('../views/public/testimonials/show')
  },
  {
    path: '/franchising-opportunities',
    name: 'Franchising Opportunities',
    component: () =>  import ('../views/public/franchising-opportunities/index.vue')
  },
  {
    path: '/franchising-opportunities/franchise-details/:id',
    name: 'Franchising Opportunity',
    component: () =>  import ('../views/public/franchising-opportunities/show.vue')
  },
  {
    path: '/restricted',
    name: 'Restricted',
    component: () =>  import ('../views/public/restricted.vue')
  },
  {
    name: 'Admin Dashboard',
    path: '/admin',
    meta: { requires_auth: true, requires_admin: true, },
    component: () => import('../views/admin/dashboard'),
    children: [
      {
        name: 'Dashboard',
        path: '',
        meta: { hide_admin_link: true },
        component: () => import('../views/admin/admin_home/admin_home'),
      },
      {
        name: 'Franchise Opportunities',
        path: 'franchise_opportunities',
        component: () => import('../views/admin/view_group'),
        children: [
          {
            name: 'Franchise Opportunities',
            path: '',
            meta: { links: ['/admin/franchise_opportunities/new'] },
            component: () => import('../views/admin/franchise_opportunities/index')
          },
          {
            name: 'New Franchise Opportunity',
            path: 'new',
            meta: { links: ['/admin/franchise_opportunities'] },
            component: () => import('../views/admin/franchise_opportunities/edit')
          },
          {
            name: 'Edit Franchise Opportunity',
            path: ':id',
            meta: { links: ['/admin/franchise_opportunities'] },
            component: () => import('../views/admin/franchise_opportunities/edit')
          }
        ]
      },
      {
        name: 'Franchise Enquires',
        path: 'franchise_enquires',
        component: () => import('../views/admin/view_group'),
        children: [
          {
            name: 'Franchise Enquires',
            path: '',
            meta: { links: ['/admin/franchise_enquires/import'] },
            component: () => import('../views/admin/franchise_enquires/index')
          },
          {
            name: 'Franchise Enquiry',
            path: ':id',
            meta: { links: ['/admin/franchise_enquires'] },
            component: () => import('../views/admin/franchise_enquires/show')
          },
          {
            name: 'Import Franchise Enquiries',
            path: 'import',
            meta: { links: ['/admin/franchise_enquires'] },
            component: () => import('../views/admin/franchise_enquires/import')
          }
        ]
      },
      {
        name: 'Financial Submissions',
        path: 'financial_submissions',
        component: () => import('../views/admin/view_group'),
        children: [
          {
            name: 'Financial Submissions',
            path: '',
            meta: { links: [] },
            component: () => import('../views/admin/financial_submissions/index')
          },
          {
            name: 'Financial Submission',
            path: ':user_id',
            meta: { links: ['/admin/financial_submissions'] },
            component: () => import('../views/admin/financial_submissions/show')
          }
        ]
      },
      {
        name: 'News',
        path: 'articles',
        component: () => import('../views/admin/view_group'),
        children: [
          {
            name: 'News',
            path: '',
            meta: { links: ['/admin/articles/new'] },
            component: () => import('../views/admin/articles/index')
          },
          {
            name: 'New News Article',
            path: 'new',
            meta: { links: ['/admin/articles'] },
            component: () => import('../views/admin/articles/edit')
          },
          {
            name: 'Edit News Article',
            path: ':id',
            meta: { links: ['/admin/articles'] },
            component: () => import('../views/admin/articles/edit')
          }
        ]
      },
      {
        name: 'Pages',
        path: 'pages',
        component: () => import('../views/admin/view_group'),
        children: [
          {
            name: 'Pages',
            path: '',
            meta: { links: ['/admin/pages/new'] },
            component: () => import('../views/admin/pages/index')
          },
          {
            name: 'New Page',
            path: 'new',
            meta: { links: ['/admin/pages'] },
            component: () => import('../views/admin/pages/edit')
          },
          {
            name: 'Edit Page',
            path: ':id',
            meta: { links: ['/admin/pages'] },
            component: () => import('../views/admin/pages/edit')
          }
        ]
      },
      {
        name: 'Snippet Testimonials',
        path: 'testimonials',
        component: () => import('../views/admin/view_group'),
        children: [
          {
            name: 'Snippet Testimonials',
            path: '',
            meta: { links: ['/admin/testimonials/new'] },
            component: () => import('../views/admin/testimonials/index')
          },
          {
            name: 'New Snippet Testimonial',
            path: 'new',
            meta: { links: ['/admin/testimonials'] },
            component: () => import('../views/admin/testimonials/edit')
          },
          {
            name: 'Edit Snippet Testimonial',
            path: ':id',
            meta: { links: ['/admin/testimonials'] },
            component: () => import('../views/admin/testimonials/edit')
          }
        ]
      },
      {
        name: 'Page Testimonials',
        path: 'case_studies',
        component: () => import('../views/admin/view_group'),
        children: [
          {
            name: 'Page Testimonials',
            path: '',
            meta: { links: ['/admin/case_studies/new'] },
            component: () => import('../views/admin/case_studies/index')
          },
          {
            name: 'New Page Testimonial',
            path: 'new',
            meta: { links: ['/admin/case_studies'] },
            component: () => import('../views/admin/case_studies/edit')
          },
          {
            name: 'Edit Page Testimonial',
            path: ':id',
            meta: { links: ['/admin/case_studies'] },
            component: () => import('../views/admin/case_studies/edit')
          }
        ]
      },
      {
        name: 'Staff Bios',
        path: 'staff_bios',
        component: () => import('../views/admin/view_group'),
        children: [
          {
            name: 'Staff Bios',
            path: '',
            meta: { links: ['/admin/staff_bios/new'] },
            component: () => import('../views/admin/staff_bios/index')
          },
          {
            name: 'New Staff Bio',
            path: 'new',
            meta: { links: ['/admin/staff_bios'] },
            component: () => import('../views/admin/staff_bios/edit')
          },
          {
            name: 'Edit Staff Bio',
            path: ':id',
            meta: { links: ['/admin/staff_bios'] },
            component: () => import('../views/admin/staff_bios/edit')
          }
        ]
      },
      {
        name: 'Navigation',
        path: 'navigation',
        component: () => import('../views/admin/view_group'),
        children: [
          {
            name: 'Navigation Lists',
            path: '',
            meta: { links: ['/admin/navigation/new'] },
            component: () => import('../views/admin/navigation/index')
          },
          {
            name: 'Edit Navigation List',
            path: ':id',
            meta: { links: ['/admin/navigation'] },
            component: () => import('../views/admin/navigation/edit')
          }
        ]
      },
      {
        name: 'Email Settings',
        path: 'email_settings',
        component: () => import('../views/admin/email_settings'),
      },
      {
        name: 'Users',
        path: 'users',
        component: () => import('../views/admin/users'),
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Page',
    meta: { some_meta_variable: 'whatever' },
    component: ()  => import('../views/public/page')
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // window.scrollTo(0, 0);


  const requires_auth = to.matched.some(x => x.meta.requires_auth);
  const requires_admin = to.matched.some(x => x.meta.requires_admin);

  //console.log("route", {requires_auth: requires_auth }, {requires_admin: requires_admin}, to.fullPath)


  // console.log("route", { name:to.name, path: to.fullPath, requires_auth: requires_auth, auth_session: router.$store.getters.auth_session });

 // next(); return; // for debug

  if (!requires_auth && !requires_admin) {

   // console.log("next");
    next();

  } else if (requires_admin && router.$store.getters.auth_session &&
                              router.$store.getters.auth_session.user &&
                              router.$store.getters.auth_session.user.app_metadata.is_admin){
    next();

  } else if(!requires_admin && requires_auth && router.$store.getters.auth_session &&
                                                router.$store.getters.auth_session.user) {
    next();
  }

  else if (router.$store.getters.auth_session && router.$store.getters.auth_session.user ){

    console.log("---restricted--")
    next(`/restricted`);

  } else {
     next(`/login?destination=${to.path}`);

  }
});

export default router
